import { useState } from "react";

type FieldErrors = Record<string, string>;

export const useFieldValidation = () => {
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({});

  // Fonction pour valider un champ spécifique
  const validateField = (fieldName: string) => {
    return (value: any) => {
      if (!value) {
        return undefined; // Ne retourne aucune erreur si le champ est vide
      }

      if (fieldErrors[fieldName]) {
        return fieldErrors[fieldName];
      }
      return undefined;
    };
  };


  // Fonction pour réinitialiser les erreurs
  const resetErrors = () => {
    setFieldErrors({});
  };

  return { fieldErrors, validateField, resetErrors, setFieldErrors };
};

export const validateVatFormat = (value: string) => {
    if (!value) {
      return undefined; // Pas d'erreur si le champ est vide
    }
  
    const tvaRegex = /^[A-Za-z]{2}[0-9]{2}[0-9]{9}$/; // Format TVA
    if (!tvaRegex.test(value)) {
      return 'Vat must be a valid TVA format (e.g., FR12345678901)';
    }
  
    return undefined; // Pas d'erreur si le format est correct
  };

  export const validateMobile = (value: string) => {
    if (!value) {
      return undefined; // Pas d'erreur si le champ est vide
    }
  
    // Regex pour valider les numéros au format international
    const mobileRegex = /^\+[1-9]\d{1,14}$/; // Format E.164
    if (!mobileRegex.test(value)) {
      return 'signPhone must be a valid mobile format (e.g., +33611223344)';
    }
  
    return undefined; // Pas d'erreur si le format est correct
  };