import React from 'react';
import { Create, SelectArrayInput, SimpleForm, TextInput } from 'react-admin';
import { roleChoices } from '../../Services/users';

export const UserCreate = (props:any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="firstname" />
      <TextInput source="lastname" />
      <TextInput source="email" />
      <TextInput source="phone" />
      <SelectArrayInput 
          source="roles" 
          choices={roleChoices} 
          optionText="name" 
          optionValue="id" 
        />
    </SimpleForm>
  </Create>
);