import { Identifier } from "react-admin";
import { IBillingAccount } from "./billingAccount";
import r from "../Utils/fetch";

const w: any = window;
const endPoint = `${w.API_BILL}`;

export interface BillAccountContract {
  id?: string;
  status?: string;
  ref?: string
  fileId?: string;
  contractDocId?: string;
  contractOriginId?: string;
  contractOriginRef?: string;
  billingAccount?: IBillingAccount
}

export const billingAccountContractPaymentType = [
  { id: 'cb', name: 'Visa Card' },
  { id: 'bank-transfer', name: 'bank transfer' },
  { id: 'sepa', name: 'sepa' },
  { id: 'free', name: 'free' },
  { id: 'other', name: 'Other' },
];

export const billingAccountContractFrequency = [
  { id: 'annualy', name: 'Annualy' },
  { id: 'monthly', name: 'Monthly' },
];


export const 
	BILLING_ACCOUNT_CONTRACT_STATUS_DELETED = "deleted", 
	BILLING_ACCOUNT_CONTRACT_STATUS_CLOSED  = "closed", 
	BILLING_ACCOUNT_CONTRACT_STATUS_REFUSED = "refused", 
	BILLING_ACCOUNT_CONTRACT_STATUS_EXPIRES = "expired", 
	BILLING_ACCOUNT_CONTRACT_STATUS_DRAFT   = "draft", 
	BILLING_ACCOUNT_CONTRACT_STATUS_TO_SIGN = "to_sign", 
	BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED  = "signed"


export const billingAccountContractStatus = [
  { id: BILLING_ACCOUNT_CONTRACT_STATUS_DRAFT, name: 'Draft' },
  { id: BILLING_ACCOUNT_CONTRACT_STATUS_TO_SIGN, name: 'To sign' },
  { id: BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED, name: 'Signed' },
  { id: BILLING_ACCOUNT_CONTRACT_STATUS_EXPIRES, name: 'Expired' },
  { id: BILLING_ACCOUNT_CONTRACT_STATUS_REFUSED, name: 'Refused' },
  { id: BILLING_ACCOUNT_CONTRACT_STATUS_DELETED, name: 'Archived' },
  { id: BILLING_ACCOUNT_CONTRACT_STATUS_CLOSED, name: 'Closed' },
];


export const prepareContractToSign= (id : Identifier): Promise<BillAccountContract> =>
  r({ url: `${endPoint}/v1/admin/billing-account-contracts/${id}/prepare`, method: "GET" });

export const sendToSign= (id : Identifier): Promise<BillAccountContract> =>
  r({ url: `${endPoint}/v1/admin/billing-account-contracts/${id}/sign`, method: "GET" });