import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import authProvider from '../../../Services/authProvider';
import { Loading } from 'react-admin';

interface ProtectedRouteProps {
  element: ReactElement;
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
      authProvider.checkAuth()
          .then(() => {
              setIsAuthenticated(true);
              setLoading(false);
          })
          .catch(() => {
              setIsAuthenticated(false);
              setLoading(false);
          });
  }, []);

  if (loading) return <Loading />;

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;