// @ts-nocheck
import React, { useState } from 'react';
import { Datagrid, EditBase, FunctionField, ReferenceInput, ReferenceManyField, required, SelectInput, SimpleForm, TextField, TextInput, TopToolbar, useRecordContext } from 'react-admin';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import ConfirmDeleteButton from '../../Components/Button/ConfirmDeleteBtn';
import ProjectUserModalCreate from './ProjectUsers/ProjectUserModalCreate';

const Breadcrumb = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Typography variant="subtitle1">
        Home / Project / {record ? record.name : 'Edit'}
      </Typography>
    </TopToolbar>
  );
};


export const ProjectEdit = (props: any) => {
  
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
  <EditBase {...props}>
   <React.Fragment>
    <Breadcrumb />
    {/* Utilisation d'une grille à deux colonnes */}
    <Grid container spacing={2}>
      {/* Colonne principale avec les projets associés */}
      <Grid item xs={8}>
        <Card>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Associated Users
              </Typography>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                      +
              </Button>
            </div>
            <ReferenceManyField
                label="Projects"
                reference="project-users"
                target="projectId"
                sort={{ field: 'user.name', order: 'ASC' }}
              >
                <Datagrid>
                  <TextField source="user.name" label="User" />
                  <TextField source="role" label="Role" />
                  <FunctionField render={record => <ConfirmDeleteButton 
                    record={record} 
                    title='Delete user on project user' 
                    entity='project-users'
                    description='Are you sure to delete this user ?'
                    withRedirect={false}
                    confirmMessage='User is deleted' />
                    } />
                </Datagrid>
              </ReferenceManyField>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Project Details
            </Typography>
            <SimpleForm>
              <TextInput source="id" disabled />
              <ReferenceInput label="Billing Account" source="billingAccountId" reference="billing-accounts" >
                <SelectInput optionText="name" validate={required()} />
              </ReferenceInput>
              
              <TextInput source="name" />
              <TextInput source="folderId" disabled />
              <TextInput source="projectDatawareHouseId" disabled />
              <TextInput source="accountEmail" disabled />
              <TextInput source="region" disabled />
            </SimpleForm>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    <ProjectUserModalCreate open={open} handleClose={handleClose} />
    </React.Fragment>
  </EditBase>
  )
};