import React, { useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { SimpleForm, Toolbar, SaveButton, TextInput, useNotify } from 'react-admin';
import EuroIcon from '@mui/icons-material/Euro';
import DeleteIcon from '@mui/icons-material/Delete';
import { IOfferDetail } from '../../../Services/offerDetails';

interface OfferDetailFormProps {
  onSubmit: (data: any) => void;
  initialValues?: IOfferDetail;
  onCancel: () => void;
}

const OfferDetailForm: React.FC<OfferDetailFormProps> = ({ onSubmit, initialValues, onCancel }) => {
  
  const notify = useNotify();
  const [lines, setLines] = useState(initialValues?.lines || []);
  const [newLine, setNewLine] = useState({ from: '', to: '', annualPrice: '', monthlyPrice: '' });

  const handleAddLine = () => {
    // Validation pour s'assurer que les champs ne sont pas vides
    if (newLine.from.length === 0 || newLine.to.length === 0 || newLine.annualPrice.length === 0 || newLine.monthlyPrice.length === 0) {
      notify('2. Please fill in all required fields: From, To, monthly Price and annual Price.', { type: 'warning' });
      return;
    }

    // Ajouter la ligne après validation
    setLines([...lines, { ...newLine, annualPrice: parseFloat(newLine.annualPrice), monthlyPrice: parseFloat(newLine.monthlyPrice) }]);
    setNewLine({ from: '', to: '', annualPrice: '', monthlyPrice: '' });
    
  };

  const handleLineChange = (field: string, value: string) => {
    const parsedValue = parseFloat(value);
    setNewLine({ ...newLine, [field]: isNaN(parsedValue) ? '' : parsedValue });
  };

  const handleDeleteLine = (index: number) => {
    // Supprimer la ligne à l'index spécifié
    const updatedLines = lines.filter((_, i) => i !== index);
    setLines(updatedLines);
    
  };

  const handleSubmit = (data: any) => {
    onSubmit({ ...data, lines });  // Ajout de `lines` dans l'objet de soumission
  };

  return (
    <SimpleForm
      
      onSubmit={handleSubmit}
      defaultValues={initialValues}
      toolbar={
        <Toolbar style={{ justifyContent: "space-between" }}>
          <SaveButton label="Save" alwaysEnable />
          <Button onClick={onCancel} color="secondary">Cancel</Button>
        </Toolbar>
      }
    >
        
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <TextInput 
            source="nbr" 
            type="number" 
            defaultValue={1}
            parse={value => parseInt(value, 10)}
          />
        </Box>
        <Box flex={1} mr="0.5em">
          <TextInput
            source="annualPrice"
            label="Annual Price"
            type="number"
            parse={value => parseFloat(value)}
            InputProps={{
              endAdornment: <EuroIcon style={{ marginLeft: 8 }} />
            }}
          />
        </Box>
        <Box flex={1} mr="0.5em">
          <TextInput 
            source="linesIncluded" 
            parse={value => parseInt(value)}
            type="number"
           />
        </Box>
      </Box>

      <Typography variant="h6" gutterBottom>
        Contract Detail Lines
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>M. Price</TableCell>
            <TableCell>A. Price</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lines.map((line, index) => (
            <TableRow key={index}>
              <TableCell>{line.from}</TableCell>
              <TableCell>{line.to}</TableCell>
              <TableCell>{line.monthlyPrice}€</TableCell>
              <TableCell>{line.annualPrice}€</TableCell>
              <TableCell>
                <Button onClick={() => handleDeleteLine(index)} color="secondary">
                  <DeleteIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
                <TextField
                  label="From"
                  value={newLine.from}
                  onChange={(e) => handleLineChange('from', e.target.value)}
                />
            </TableCell>
            <TableCell>
                <TextField
                  label="To"
                  value={newLine.to}
                  onChange={(e) => handleLineChange('to', e.target.value)}
                />
            </TableCell>
            <TableCell>
            <TextField
                  label="A. Price"
                  value={newLine.monthlyPrice}
                  onChange={(e) => handleLineChange('monthlyPrice', e.target.value)}
                  type="number"
                />
            </TableCell>
            <TableCell>
            <TextField
                  label="M. Price"
                  value={newLine.annualPrice}
                  onChange={(e) => handleLineChange('annualPrice', e.target.value)}
                  type="number"
                />
            </TableCell>
            <TableCell>
              <Button onClick={handleAddLine} color="primary">+</Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </SimpleForm>
  );
};

export default OfferDetailForm;
