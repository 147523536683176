import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, EditButton, SelectInput, FunctionField, Button } from 'react-admin';
import { subscriptionStatusChoices, take } from '../../Services/users';
import ConfirmDeleteButton from '../../Components/Button/ConfirmDeleteBtn';
import HanshakeIcon from '@mui/icons-material/Handshake';

const getStatusName = (statusId: number) => {
  const status = subscriptionStatusChoices.find(choice => choice.id === statusId);
  return status ? status.name : '';
};


const getRowStyle = (record: any) => {

  if (record.subscriptionStatus === 100) {
    return { backgroundColor: '#f8d7da' };
  } else if (record.subscriptionStatus === 0) {
    return { backgroundColor: '#fff3cd' };
  }
  return {}; // Pas de style pour les autres statuts
};


const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    {/* Ajout du filtre pour subscriptionStatus */}
    <SelectInput 
      label="Subscription Status"
      source="subscriptionStatus"
      choices={subscriptionStatusChoices}
    />
  </Filter>
);
export const UserList = (props: any) => (
  <List {...props} filters={<UserFilter />}>
    <Datagrid rowClick={(id, basePath, record) => `${record.id}`} rowSx={(record) => getRowStyle(record)}>
      <TextField source="id" />
      <FunctionField 
        label="Subscription Status"
        render={record => getStatusName(record.subscriptionStatus)}
      />
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="roles" />
      <FunctionField render={record => <TakeUser record={record} />} />
      <EditButton label="Edit" />
      <FunctionField render={record => <ConfirmDeleteButton 
        record={record} 
        title='Delete billing account' 
        entity='users'
        description='Are you sure to delete this user ?'
        confirmMessage='User is deleted' />
        } />
    </Datagrid>
  </List>
);

const TakeUser = ({ record }: { record: any  }) => {

  const[isPending, setIsPending] = React.useState(false); 

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsPending(true);
    try {
      const res = await take(record.fb_uuid);

      const w: any = window;
      const endPoint = `${w.PUBLIC_URL}`;

      window.open(`${endPoint}/signin?token=${res.token}`, '_blank');

    }catch(e) {
      alert('An error occured');
    }finally {
      setIsPending(false);
    }
 
  }

  return (
    <>
      {record.fb_uuid && 
        <Button
          label="Take"
          color='primary'
          onClick={handleClick}
          disabled={isPending}
        >
          <HanshakeIcon />
        </Button>
      }
      {!record.fb_uuid && <span>Never conneted</span>}
    </>
  );
};

export default TakeUser;