import r from "../Utils/fetch";

const w: any = window;
const endPoint = `${w.API_CORE}`;

const endPoints = {
  users: `${endPoint}/v1/users`,
  beta: `${endPoint}/v1/beta-testers`,
};

export const subscriptionStatusChoices = [
  { id: 0, name: 'En attente' },
  { id: 100, name: 'Refusé' },
  { id: 200, name: 'Accepté' }
];

export const roleChoices = [
  { id: 'ROLE_ADMIN', name: 'Admin' },
  { id: 'ROLE_USER', name: 'User' },
  { id: 'ROLE_GUEST', name: 'Guest' } // Ajouter d'autres rôles si nécessaire
];

export interface User {
  id: number
  name: string
  phone: string
  email: string
  subscriptionStatus: number
  roles: string[]
  createdAt?: Date
}

interface ITake {
  token: string
}
export interface UserPayload extends Omit<User, "id"> {}

export const getMe = (): Promise<User> =>
  r({ url: `${endPoints.users}/me`, method: "GET" });

export const take = (fb_uuid: string): Promise<ITake> =>
  r({ url: `${endPoint}/v1/admin/users/take`, method: "GET", data: {fbUuid: fb_uuid}} );



