import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, List, ListItemButton, ListItemText, DialogContent } from '@mui/material';
import { useNotify, useRefresh, useRecordContext, useCreate, useDataProvider  } from 'react-admin';
import { Offer } from '../../../Services/offer';
import OfferDetailForm from './OfferDetailForm';
import { IOfferDetail } from '../../../Services/offerDetails';

interface OfferDetailsModalCreateProps {
  open: boolean;
  handleClose: () => void;
}

const OfferDetailsModalCreate: React.FC<OfferDetailsModalCreateProps> = ({ open, handleClose }) => {
  const record = useRecordContext<Offer>(); // Contexte pour obtenir le user actuel
  const notify = useNotify();
  const refresh = useRefresh();
  const [create] = useCreate();
  const dataProvider = useDataProvider();
  const [isPending, setIsPending] = useState(false);
  const [offer, setOffer] = useState<Offer | null>(null);
  const [offerDetail, setOfferDetail] = useState<IOfferDetail | null>(null);


  const handleSave = (data: any) => {
 
    create(
      'offer-details',
      { data: { ...data, billingAccountContractId: record?.id, offerId: null } },
      {
        onSuccess: () => {
          refresh();
          notify('Line created successfully');
          onClose();
        },
        onError: (error: any) => {
          notify(`Error: ${error.message}`, { type: 'error' });
        }
      }
    );
  };

  const onClose = () => {
    handleClose();
    setOfferDetail(null);
  }

  useEffect(() => {
    if (open) {
      // Charger la liste des offres lorsque la modal est ouverte
      setIsPending(true);
      dataProvider
        .getOne('offers', {
          id: "current"
        })
        .then(({ data }) => {
          setOffer(data);
        })
        .catch((error) => {
          notify(`Error loading offers: ${error.message}`, { type: 'error' });
        })
        .finally(() => {
          setIsPending(false);
        });
    }
  }, [open, dataProvider, notify]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{offerDetail ? `Add ${offerDetail.skuParent} ${offerDetail.sku}` : `Select Sku`}</DialogTitle>

        {isPending && <DialogContent>Loading...</DialogContent>}
        <DialogContent>
        {offer && !offerDetail && (
    
          <List>
            {offer.offerDetails.map((offerDetail: IOfferDetail) => (
              <ListItemButton 
                key={offerDetail.id}
                onClick={() => setOfferDetail(offerDetail)}
              >
                <ListItemText primary={offerDetail.skuParent} secondary={offerDetail.sku ? offerDetail.sku : "All connectors" } />
                <ListItemText sx={{ textAlign: 'right' }} primary={new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(offerDetail.annualPrice!)} />
            </ListItemButton>
            ))}
          </List>
    
        )}
        </DialogContent>

      {offerDetail && <OfferDetailForm onSubmit={handleSave} initialValues={{
        sku: offerDetail.sku,
        skuParent: offerDetail.skuParent,
        annualPrice: offerDetail.annualPrice,
        nbrType: offerDetail.nbrType,
        lines: offerDetail.lines
      } as IOfferDetail} onCancel={() => setOfferDetail(null)} />}
    </Dialog>
  );
};

export default OfferDetailsModalCreate;
