import React from 'react';
import { Admin, CustomRoutes, Resource, defaultTheme } from 'react-admin';
import authProvider from './Services/authProvider';
import { deepmerge } from '@mui/utils';
import MyLoginPage from './Screens/Login';
import { ProjectList } from './Screens/Projects/ProjectList';
import { ProjectEdit } from './Screens/Projects/ProjectEdit';
import { ProjectCreate } from './Screens/Projects/ProjectCreate';
import Home from './Screens/Home';
import { BrowserRouter, Route } from 'react-router-dom';
import apiProvider from './Services/dataProvider';
import { UserCreate } from './Screens/Users/UserCreate';
import { UserEdit } from './Screens/Users/UserEdit';
import { UserList } from './Screens/Users/UserList';
import PeopleIcon from '@mui/icons-material/People';
import FactoryIcon from '@mui/icons-material/Factory';
import LocalOffer from '@mui/icons-material/LocalOffer';
import CreditCard from '@mui/icons-material/CreditCard';

import { orange, pink, red } from '@mui/material/colors'; // Import des couleurs MUI
import ProtectedRoute from './Components/Layout/ProtectedRoute';

//import { setLogLevel } from "firebase/app";
import { BillingAccountList } from './Screens/BillingAccounts/BillingAccountList';
import { BillingAccountEdit } from './Screens/BillingAccounts/BillingAccountEdit';
import { BillingAccountCreate } from './Screens/BillingAccounts/BillingAccountCreate';
import { OfferList } from './Screens/Offers/OfferList';
import { OfferCreate } from './Screens/Offers/OfferCreate';
import { OfferEdit } from './Screens/Offers/OfferEdit';
import { BillingAccountContractEdit } from './Screens/BillingAccountContracts/BillingAccountContractEdit';
import { BillingAccountAccountList } from './Screens/BillingAccountContracts/BillingAccountContracList';
import CustomLayout from './Components/Layout/Layout';
//setLogLevel('debug');  // Active les logs de débogage

const myTheme = deepmerge(defaultTheme, {
    palette: {
        primary: {
            main: orange[900], // Couleur principale de la barre du haut
            light: orange[300], // Couleur pour les éléments plus clairs
            dark: orange[700], // Couleur pour les éléments plus foncés
            contrastText: '#fff', // Couleur du texte contrasté sur la barre du haut
        },
        secondary: {
            main: orange[900],
            light: pink[300],
            dark: pink[700],
            contrastText: '#fff',
        },
        error: {
            main: red[500],
            light: red[300],
            dark: red[700],
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Utilisation de la police de caractères système.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
});


const App = () => (
  <BrowserRouter>

      <Admin
        theme={myTheme}
        dataProvider={apiProvider}
        authProvider={authProvider}
        layout={CustomLayout}
        loginPage={MyLoginPage} // Utiliser la page de connexion personnalisée
      >
        <CustomRoutes>
          <Route path="/" element={<ProtectedRoute element={<Home />} />} />
        </CustomRoutes>

        <Resource name="projects" list={ProjectList} edit={ProjectEdit} create={ProjectCreate} />
        <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} icon={PeopleIcon} />
        <Resource name="billing-accounts" list={BillingAccountList} edit={BillingAccountEdit} create={BillingAccountCreate} icon={FactoryIcon} />
        <Resource name="offers" list={OfferList} icon={LocalOffer} create={OfferCreate} edit={OfferEdit} />
        <Resource name="billing-account-contracts" icon={LocalOffer} list={BillingAccountAccountList} edit={BillingAccountContractEdit} />
        <Resource name="billing-account-payment-types" icon={CreditCard} />
        <Resource name="billing-account-contract-documents" icon={CreditCard} />
      </Admin>

  
  </BrowserRouter>
);

export default App;