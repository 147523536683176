import React from 'react';
import { Box } from '@mui/material';
import { Loading } from 'react-admin';
interface BillingAccountContractModalCreateProps {
  show: boolean;
}

const Loader: React.FC<BillingAccountContractModalCreateProps> = ({ show= true }) => {
  
if (!show) return null;

  return (
    <Box 
      position={"absolute"} width={"100%"} 
      top={0} 
      left={0} 
      right={0} 
      bottom={0} 
      zIndex={10} 
      className="overlay"
      sx={{backgroundColor: 'rgba(255, 255, 255, 0.8)'}}
    >
        <Loading />
      </Box>
  );
};

export default Loader;
