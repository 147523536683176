import React from 'react';
import { BooleanInput, Create, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { billingAccountTypeChoices, countryChoices, currencies } from '../../Services/billingAccount';
import { useFieldValidation, validateMobile, validateVatFormat } from '../../Utils/validators';

export const BillingAccountCreate = (props:any) => {
  
  const { validateField } = useFieldValidation();

  return (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="organisationName" />
      <TextInput source="shareCapital"  />
      <SelectInput source="shareCapitalCurrency" choices={currencies} optionText="name" optionValue="id" />
      <TextInput source="tradeAndCompaniesRegister" />
      <TextInput source="siren" />
      <TextInput source="duns" />
      <TextInput source="vat" validate={ [validateVatFormat,validateField('vat')]} />
      <TextInput source="adress" />
      <TextInput source="zipCode" />
      <TextInput source="city" />
      <SelectInput source="country" choices={countryChoices} optionText="name" optionValue="id" />
      <TextInput source="signFirstname" />
      <TextInput source="signLastname" />
      <TextInput source="signQuality"/>
      <TextInput source="signPhone"  validate={ [validateMobile, validateField('signPhone')]} />
      <TextInput source="signEmail" />
      <TextInput source="billEmail" />

      <SelectInput source="billingAccountType" choices={billingAccountTypeChoices} optionText="name" optionValue="id" validate={ [validateField('billingAccountType')]} />
      <BooleanInput label="Indice Syntec" source="syntecIndice" />
      
    </SimpleForm>
  </Create>
  )
};