//@ts-nocheck
import React, { useState } from 'react';
import { EditBase, TopToolbar, SimpleForm, TextInput, SelectInput, ReferenceManyField, Datagrid, TextField, useRecordContext, FunctionField, BooleanInput, useGetOne, useNotify, useDataProvider } from 'react-admin';
import { Card, CardContent, Typography, Grid, Button, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import RefreshIcon from  '@mui/icons-material/Refresh';
import { BILLING_ACCOUNT_STATUS_OK, BILLING_ACCOUNT_TYPE_ENTREPRISE, BILLING_ACCOUNT_TYPE_PAYASYOUGO, billingAccountStatus, billingAccountTypeChoices, countryChoices, currencies, refresh } from '../../Services/billingAccount';
import BillingAccountUserModalCreate from './BillingAccountUsers/BillingAccountUserModalCreate';
import BillingAccountContractModalCreate from './BillingAccountContracts/BillingAccountContractModalCreate';
import ConfirmDeleteButton from '../../Components/Button/ConfirmDeleteBtn';
import { green, red } from '@mui/material/colors';
import { useParams } from 'react-router';
import { useFieldValidation, validateMobile, validateVatFormat } from '../../Utils/validators';


const Breadcrumb = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Typography variant="subtitle1">
        Home / Billing Account / {record ? record.name : 'Edit'}
      </Typography>
    </TopToolbar>
  );
};


export const BillingAccountEdit = (props: any) => {
  
  const { id } = useParams();

  const { data: record, isLoading, refetch } = useGetOne('billing-accounts', { id });

  const [userOpen, setUserOpen] = useState(false);
  const [contractOpen, setContractOpen] = useState(false);
  const [refreshIsPending, setRefreshIsPending] = useState(false);

  const { setFieldErrors, validateField } = useFieldValidation();

  const notify = useNotify();

  const handleUserOpen = () => setUserOpen(true);
  const handleUserClose = () => setUserOpen(false);

  const handleContractOpen = () => setContractOpen(true);
  const handleContractClose = () => setContractOpen(false);

  const dataProvider = useDataProvider();

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR'); // Format français : JJ/MM/AAAA
  };

  const getStatusName = (status: number) => {
    const statusObj = billingAccountStatus.find((s) => s.id === status);
    return statusObj ? statusObj.name : 'Unknown Status';
  };


  const handleSubmit = async (data: any) => {

    try {
      await dataProvider.update('billing-accounts', {
        id: record.id,
        data,
        previousData: record,
      });
      notify('Billing account updated successfully!', { type: 'success' });
    } catch (error: any) {

      if(error.body.fields) {
        const errors = error.body.fields.reduce(
          (acc: Record<string, string>, fieldError: { field: string; message: string }) => {
            acc[fieldError.field] = fieldError.message;
            return acc;
          },
          {}
          
        );
        setFieldErrors(errors);
      }
      notify(`Error: ${error.message}`, { type: 'error' });
    }
  };

  const handleRefresh = async () => {

    setRefreshIsPending(true);
    try {
      await refresh(record.id);
      notify('Billing account refreshed successfully!', { type: 'success' });
      refetch();
    }catch(error: any) {
      notify(`Error: ${error.message}`, { type: 'error' });
    }
    setRefreshIsPending(false);
    
  }

  if(isLoading) return null;
  if(!record) return null;

  return (
  <EditBase {...props}>
    <React.Fragment>
    <Breadcrumb />
    {/* Utilisation d'une grille à deux colonnes */}
    <Grid container spacing={2}>
      {/* Colonne principale avec les projets associés */}
      <Grid item xs={8}>
      {record && record.billingAccountType === BILLING_ACCOUNT_TYPE_PAYASYOUGO && 
      <Card style={{ marginBottom: 20 }}>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Payment cards
              </Typography>
            </div>

            <ReferenceManyField
              label="Payments cards"
              reference="billing-account-payment-types"
              target="billingAccountId"
            >
               <Datagrid bulkActionButtons={false}>
                <FunctionField source="startedAt"  label="Started At" render={rec => formatDate(rec.startedAt)} />
                <FunctionField source="endedAt"  label="Ended At" render={rec => formatDate(rec.endedAt)} />
                <FunctionField source="amount"  label="Amount" render={rec => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(rec.amount!)} />
              
                <TextField source="status" label="Status" />
                <FunctionField render={rec => <ConfirmDeleteButton 
                  record={rec} 
                  title='Delete Contract' 
                  entity='billing-account-contracts'
                  description='Are you sure to delete this contract ?'
                  withRedirect={false}
                  confirmMessage='Contract is deleted' />
                  } />
              </Datagrid>
            </ReferenceManyField>
          </CardContent>
        </Card>
        }

        {record && record.billingAccountType === BILLING_ACCOUNT_TYPE_ENTREPRISE && 
        <Card style={{ marginBottom: 20 }}>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Contracts
              </Typography>
              <Button variant="contained" color="primary" onClick={handleContractOpen}>
                    +
              </Button>
            </div>

            <ReferenceManyField
              label="Contracts"
              reference="billing-account-contracts"
              target="billingAccountId"
              sort={{ field: '"BillingAccountContract"."startedAt"', order: 'DESC' }}
            >
               <Datagrid bulkActionButtons={false}>
                <TextField source="ref" label="Ref" />
                <TextField source="contractOrigin.ref" label="Ref Origin" />
                <FunctionField source="startedAt"  label="Started At" render={rec => formatDate(rec.startedAt)} />
                <FunctionField source="endedAt"  label="Ended At" render={rec => formatDate(rec.endedAt)} />
                <FunctionField source="amount"  label="Amount" render={rec => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(rec.amount!)} />
              
                <TextField source="status" label="Status" />

                  <FunctionField render={
                    rec => (
                      <div>
                        <ConfirmDeleteButton 
                          record={rec} 
                          title='Delete Contract' 
                          entity='billing-account-contracts'
                          description='Are you sure to delete this contract ?'
                          withRedirect={false}
                          confirmMessage='Contract is deleted' />
                      </div>
                    )
                  } />
              </Datagrid>
            </ReferenceManyField>
          </CardContent>
        </Card>
        }

        {record && record.billingAccountType === BILLING_ACCOUNT_TYPE_ENTREPRISE && 
        <Card style={{ marginBottom: 20 }}>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Sku's
              </Typography>
            </div>

            <ReferenceManyField
              label="Offer Details"
              reference="offer-details"
              target="billingAccountId"
              
            >
              <Datagrid bulkActionButtons={false}>
                <TextField source="skuParent" label="Sku Parent" />
                <TextField source="sku" label="Sku" />
                <TextField source="nbr" label="nbr" />
                <TextField source="nbrType" label="nbrType" />
                <TextField source="billingAccountContract.ref" label="Contract" />
                <TextField source="billingAccountContract.status" label="Status" />
              </Datagrid>
            </ReferenceManyField>
          </CardContent>
        </Card>
        }

        <Card style={{ marginBottom: 20 }}>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Users
              </Typography>
              <Button variant="contained" color="primary" onClick={handleUserOpen}>
                    +
              </Button>
            </div>

            <ReferenceManyField
              label="Users"
              reference="billing-account-users"
              target="billingAccountId"
              sort={{ field: '"User"."name"', order: 'ASC' }}
            >
               <Datagrid bulkActionButtons={false}>
                <TextField source="user.name" label="User" />
                <TextField source="role" label="Role" />
                <FunctionField render={record => <ConfirmDeleteButton 
                  record={record} 
                  title='Delete billing account user' 
                  entity='billing-account-users'
                  description='Are you sure to delete this user ?'
                  withRedirect={false}
                  confirmMessage='User is deleted' />
                  } />
              </Datagrid>
            </ReferenceManyField>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Projects
              </Typography>
            </div>

            <ReferenceManyField
              label="Contracts"
              reference="projects"
              target="billingAccountId"
            >
              <Datagrid>
                <TextField source="name" label="Project" />
              </Datagrid>
            </ReferenceManyField>
          </CardContent>
        </Card>
      </Grid>

      <RecordContextWrapper>
      {record => (
        <>
        <BillingAccountUserModalCreate open={userOpen} handleClose={handleUserClose} billingAccountId={record?.id} />
        <BillingAccountContractModalCreate open={contractOpen} billingAccount={record} handleClose={handleContractClose} />
        </>
      )}
      </RecordContextWrapper>

      {/* Colonne secondaire avec le formulaire utilisateur */}
      <Grid item xs={4}>

        <SimpleForm
             onSubmit={handleSubmit} 
        >

          <Card style={{ marginBottom: 20, width: '100%' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Billing Account Status
              </Typography>
              
          
              <SelectInput source="billingAccountType" choices={billingAccountTypeChoices} optionText="name" optionValue="id" />

              <div>
                <Typography
                  style={{
                    display: 'inline-block',
                    color: record.enabled ? green[500] : red[500], // Couleur conditionnelle
                    fontWeight: 'bold',
                  }}
                >
                {record.enabled ? 'Account is enabled' : 'Account is disabled'}
                </Typography>
              </div>
            <div>
              <Typography
                style={{
                  display: 'inline-block',
                  color: record.status === BILLING_ACCOUNT_STATUS_OK ? green[500] : red[500], // Couleur conditionnelle
                  fontWeight: 'bold',
                }}
              >
                {getStatusName(record.status)}
              </Typography>
            </div>
            </CardContent>
          </Card>

          <Card style={{ marginBottom: 20, width: '100%' }}>
          <MenuList>
              <MenuItem onClick={handleRefresh} disabled={refreshIsPending}>
                <ListItemIcon>
                  <RefreshIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Refresh</ListItemText>
              </MenuItem>
          </MenuList>
        </Card>

          <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Billing Account Details
            </Typography>
              <TextInput source="id" disabled />
              <TextInput source="name" />
              <TextInput source="organisationName" />
              <TextInput source="shareCapital"  />
              <SelectInput source="shareCapitalCurrency" choices={currencies} optionText="name" optionValue="id" />
              <TextInput source="tradeAndCompaniesRegister" />
              <TextInput source="siren" />
              <TextInput source="duns" />
              <TextInput source="adress" />
              <TextInput source="zipCode" />
              <TextInput source="city" />
              <SelectInput source="country" choices={countryChoices} optionText="name" optionValue="id" />
              <TextInput source="signFirstname" />
              <TextInput source="signLastname" />
              <TextInput source="signQuality"/>
              <TextInput source="signPhone"  validate={ [validateMobile, validateField('signPhone')]} />
              <TextInput source="signEmail" />
              <TextInput source="billEmail" />
              <TextInput source="vat" validate={ [validateVatFormat,validateField('vat')]} />
              <BooleanInput label="Indice Syntec" source="syntecIndice" />
            </CardContent>
          </Card>
        </SimpleForm>
        
      </Grid>
    </Grid>
    </React.Fragment>
  </EditBase>
)};

const RecordContextWrapper: React.FC<{ children: (record: any) => React.ReactNode }> = ({ children }) => {
  const record = useRecordContext();
  
  if (!record) {
    return null; // Retournez null ou un spinner si le record est encore en cours de chargement
  }

  return <>{children(record)}</>;
};
