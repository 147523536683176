//@ts-nocheck
import React, { useState } from 'react';
import { BooleanInput, Datagrid, DateInput, EditBase, FunctionField, ReferenceManyField, SimpleForm, TextField, TextInput, TopToolbar, useRecordContext } from 'react-admin';
import { Card, CardContent, Typography, Grid, Button, IconButton, Menu, MenuItem } from '@mui/material';
import ConfirmDeleteButton from '../../Components/Button/ConfirmDeleteBtn';
import OfferDetailsModalCreate from './OfferDetails/OfferDetailsModalCreate';
import OfferDetailsModalEdit from './OfferDetails/OfferDetailsModalEdit';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const Breadcrumb = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <Typography variant="subtitle1">
        Home / Offers / {record ? record.name : 'Edit'}
      </Typography>
    </TopToolbar>
  );
};


export const OfferEdit = (props: any) => {
  
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [menuRecord, setMenuRecord] = useState(null);

  const handleCreateOpen = (defaults: any = null) => {
    setDefaultValues(defaults); // Met à jour les valeurs par défaut
    setCreateOpen(true);
  };

  const handleCreateClose = () => setCreateOpen(false);

  const handleEditOpen = (record: any) => {

    setSelectedRecord(record);
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setSelectedRecord(null);
    setEditOpen(false);
  };

  const copy = (record: any) => {
    const newRecord = {
      ...record,
      id: null, // Génère un nouvel ID unique
    };
    delete newRecord.createdAt; // Suppression des champs non nécessaires si applicable
    delete newRecord.updatedAt;
    handleCreateOpen(newRecord);
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, record: any) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuRecord(record);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuRecord(null);
  };
  
  return (
  <EditBase {...props}>
   <React.Fragment>
    <Breadcrumb />
    {/* Utilisation d'une grille à deux colonnes */}
    <Grid container spacing={2}>
      {/* Colonne principale avec les projets associés */}
      <Grid item xs={8}>
        <Card>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Offer Details
              </Typography>
              <Button variant="contained" color="primary" onClick={() => handleCreateOpen(null)}>
                      +
              </Button>
            </div>
            <ReferenceManyField
                label="Offer"
                reference="offer-details"
                target="offerId"
                sort={{ field: 'offerDetails.id', order: 'ASC' }}
              >
                <Datagrid>
                <TextField source="skuParent" label="Sku Parent" />
                  <TextField source="sku" label="Sku" />
                  <TextField source="nbr" label="nbr" />
                  <TextField source="nbrType" label="nbrType" />
                  <TextField source="monthlyPrice" label="monthlyPrice" />
                  <TextField source="annualPrice" label="annualPrice" />
                  <TextField source="linesIncluded" label="linesIncluded" />
                  <TextField source="enabled" label="enabled" />
                  <FunctionField
                      render={(record) => (
                        <>
                          <IconButton
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleMenuOpen(event, record)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </>
                      )}
                    />
                  <FunctionField render={record => <ConfirmDeleteButton 
                    record={record} 
                    title='Delete details on offer' 
                    entity='offer-details'
                    description='Are you sure to delete this line ?'
                    withRedirect={false}
                    confirmMessage='Line is deleted' />
                    } />
                </Datagrid>
              </ReferenceManyField>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Offer Details
            </Typography>
            <SimpleForm>
              <TextInput source="id" disabled />
              <TextInput source="name" />
              <DateInput source="startedAt" />
              <DateInput source="endedAt" />
              <BooleanInput label="Enabled" source="enabled" />
            </SimpleForm>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
    <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleEditOpen(menuRecord)}>Edit</MenuItem>
          <MenuItem onClick={() => copy(menuRecord)}>Copy</MenuItem>
        </Menu>
      <OfferDetailsModalCreate open={createOpen} handleClose={handleCreateClose} defaultValues={defaultValues} />
      <OfferDetailsModalEdit open={editOpen} handleClose={handleEditClose} record={selectedRecord}  />
    </React.Fragment>
  </EditBase>
  )
};