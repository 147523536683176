import { fetchUtils, DataProvider, GetListParams, GetOneParams, GetManyParams, GetManyReferenceParams, CreateParams, UpdateParams, UpdateManyParams, DeleteParams, RaRecord } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';

const w: any = window;

const billRessources = ['offers', "offer-details", "skus", "billing-accounts", "billing-account-users", "billing-account-contracts", "billing-account-payment-types", "billing-account-contract-documents"];

// Personnaliser la fonction de requête
const httpClient = (url: string, options: any = {}) => {

  const authToken = localStorage.getItem('token'); // Récupérer le token enregistré
  
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  // Ajouter l'en-tête `Authorization` si un token est présent
  if (authToken) {
    options.headers.set('Authorization', `Bearer ${authToken}`);
  }

  return fetchUtils.fetchJson(url, options);
};

// Providers spécifiques pour chaque API
const apiCoreProvider = simpleRestProvider(`${w.API_CORE}/v1/admin`, httpClient) as DataProvider;
const billProvider = simpleRestProvider(`${w.API_BILL}/v1/admin`, httpClient) as DataProvider;

// Provider principal qui redirige en fonction des ressources
const apiProvider: DataProvider = {
  getList: (resource: string, params: GetListParams) => {
    if (billRessources.includes(resource)) {
      return billProvider.getList(resource, params);
    }
    return apiCoreProvider.getList(resource, params);
  },
  getOne: (resource: string, params: GetOneParams) => {
    if (billRessources.includes(resource)) {
      return billProvider.getOne(resource, params);
    }
    return apiCoreProvider.getOne(resource, params);
  },
  getMany: (resource: string, params: GetManyParams) => {
    if (billRessources.includes(resource)) {
      return billProvider.getMany(resource, params);
    }
    return apiCoreProvider.getMany(resource, params);
  },
  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    if (billRessources.includes(resource)) {
      return billProvider.getManyReference(resource, params);
    }
    return apiCoreProvider.getManyReference(resource, params);
  },
  create: (resource: string, params: CreateParams) => {
    if (billRessources.includes(resource)) {
      return billProvider.create(resource, params);
    }
    return apiCoreProvider.create(resource, params);
  },
  update: (resource: string, params: UpdateParams) => {
    if (billRessources.includes(resource)) {
      return billProvider.update(resource, params);
    }
    return apiCoreProvider.update(resource, params);
  },
  updateMany: <RecordType extends RaRecord = any>(resource: string, params: UpdateManyParams<RecordType>) => {
    if (billRessources.includes(resource)) {
      return billProvider.updateMany(resource, params);
    }
    return apiCoreProvider.updateMany(resource, params);
  },
  delete: (resource: string, params: DeleteParams) => {
    if (billRessources.includes(resource)) {
      return billProvider.delete(resource, params);
    }
    return apiCoreProvider.delete(resource, params);
  },
  deleteMany: <RecordType extends RaRecord = any>(resource: string, params: GetManyParams<RecordType>) => {
    if (billRessources.includes(resource)) {
      return billProvider.deleteMany(resource, params);
    }
    return apiCoreProvider.deleteMany(resource, params);
  },
};

export default apiProvider;
