import { Identifier } from "react-admin";
import r from "../Utils/fetch";
import { BillAccountContract } from "./billingAccountContract";

const w: any = window;
const endPoint = `${w.API_BILL}`;


export const generateDocuments = (id : Identifier): Promise<BillAccountContract> =>
  r({ url: `${endPoint}/v1/admin/billing-account-contract-documents/generate/${id}`, method: "GET" });

export const addDocument= (id : Identifier, data: any): Promise<BillAccountContract> =>
  r({ url: `${endPoint}/v1/admin/billing-account-contract-documents`, method: "POST", data });

export const removeDocument= (id : Identifier): Promise<BillAccountContract> =>
  r({ url: `${endPoint}/v1/admin/billing-account-contract-documents/${id}`, method: "DELETE"});

export const refreshDocument= (id : Identifier): Promise<BillAccountContract> =>
  r({ url: `${endPoint}/v1/admin/billing-account-contract-documents/${id}/refresh`, method: "GET"});

