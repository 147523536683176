import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import * as userService from './users';
import { UserIdentity } from "react-admin";

const w: any = window;

const firebaseConfig = {
    apiKey: w.FIREBASE_API_KEY,
    authDomain: w.FIREBASE_AUTH_DOMAINE,
    projectId: w.FIREBASE_PROJECT_ID,
    storageBucket: w.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: w.FIREBASE_MESSAGING_SENDER_ID,
    appId: w.FIREBASE_APP_ID,
};

export const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: 'select_account'
});


// Initialiser Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const useAuthRedirect = () => {
  const navigate = useNavigate();

  return {
      redirectToHome: () => {
          navigate('/');
      }
  }
}

const authProvider = {
  loginGoogle: () => {
    return new Promise<any>((resolve, reject) => {
      signInWithPopup(auth, googleProvider)
        .then(async (result) => {
          const credential = GoogleAuthProvider.credentialFromResult(result);
  
          if (!credential) {
            reject(new Error('No credential found'));
            return;
          }
  
          const user = result.user;
          const token = await user.getIdToken();
          await localStorage.setItem('token', token);
          
          const userInfo = await userService.getMe();
          await localStorage.setItem('user', JSON.stringify(userInfo));
          
        
          resolve(credential); // Assurez-vous que cela ne résout qu'après la sauvegarde
        })
        .catch((error) => {
          reject("Error: " + error.message);
        });
    });
  },
  
  login: ({ username, password }: { username: string; password: string }) => {

    return signInWithEmailAndPassword(auth, username, password)
      .then(async ({ user }) => {
      
        // Récupérer les informations de l'utilisateur (y compris les rôles)
        const userInfo = await userService.getMe();
        localStorage.setItem('user', JSON.stringify(userInfo));

      })
      .catch((error: any) => {
        throw new Error(error.message);
      });
  },

  logout: () => {
    return signOut(auth)
      .then(() => {
        localStorage.removeItem('auth');
        localStorage.removeItem('user'); // Retirer les informations de l'utilisateur
      })
      .catch((error: any) => {
        throw new Error(error.message);
      });
  },

  checkError: (error: any) => {
    if (error.status === 401 || error.status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getIdentity: () => {
    return new Promise<UserIdentity>(async (resolve, reject) => {
      try {
        const user = await userService.getMe();
       
        // Mapper l'utilisateur pour qu'il soit compatible avec `UserIdentity`
        const userIdentity: UserIdentity = {
          id: user.id, 
          fullName: user.name || user.email,
          roles: user.roles || [], 
        };
  
        resolve(userIdentity);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  },

  checkAuth: () => {
    // Vérifie l'état de l'authentification Firebase et les rôles
    return new Promise<void>((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {

        if (user) {
          // Vérifier si l'utilisateur possède le rôle "admin"
          const userInfo = JSON.parse(localStorage.getItem('user') || '{}');

          if (userInfo.user && userInfo.user.roles && userInfo.user.roles.includes('ROLE_ADMIN')) {

            resolve(); // Autoriser l'accès
          } else {
            console.log("not admin")
            reject("your are not autorized to access"); // Refuser l'accès si le rôle "admin" n'est pas trouvé
          }
        } else {
          console.log("not connected")
          reject(); // Rejeter si l'utilisateur n'est pas authentifié
        }
      });
    });
  },

  getPermissions: () => {
    const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    return Promise.resolve(userInfo.roles || []);
  },
};

export default authProvider;
