import React from 'react';
import { Box, Button, DialogContent } from '@mui/material';
import { SimpleForm, Toolbar, SaveButton, DateInput, BooleanInput } from 'react-admin';
import { BillAccountContract } from '../../../Services/billingAccountContract';

interface BillingAccountContractFormProps {
  onSubmit: (data: any) => void;
  initialValues?: BillAccountContract;
  onCancel: () => void;
}

const BillingAccountContractForm: React.FC<BillingAccountContractFormProps> = ({ onSubmit, initialValues, onCancel }) => {


  return (
    <SimpleForm
      onSubmit={onSubmit}
      defaultValues={initialValues}
      toolbar={
        <Toolbar style={{ justifyContent: "space-between" }}>
          <SaveButton label="Save" />
          <Button onClick={onCancel} color="secondary">Cancel</Button>
        </Toolbar>
      }
    >      
      <DialogContent>
        <Box display="flex" flexDirection="row" width="100%">
          <Box flex={1} mr="0.5em">
            <DateInput source="startedAt" />
          </Box>
          <Box flex={1} mr="0.5em">
            <DateInput source="endedAt" />
          </Box>
        </Box>
        <BooleanInput label="Automatic Renewal" source="automaticRenewal" />
      </DialogContent>
    </SimpleForm>
  );
};

export default BillingAccountContractForm;
