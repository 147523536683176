import React from 'react';
import {Login } from 'react-admin';
import authProvider from '../../Services/authProvider';
import { useNavigate } from 'react-router-dom';
import GoogleBtn from '../../Components/Button/GoogleBtn';

const MyLoginPage = () => {
  

  const navigate = useNavigate();

  const signWithGoogle = async () => { 

    const res = await authProvider.loginGoogle();
    
    if (res) {
      navigate('/');
    } else {
      console.log("Error");
    }

  }

  return (
  <div>
    
    <Login>
      <div style={{padding: 10}}>
        <div style={{display: "flex", justifyContent: 'center', alignItems: 'center', paddingBottom: 30}}>
            
            <GoogleBtn onClick={() => signWithGoogle()}>
              Signin with Google
            </GoogleBtn>
        </div>
       

      </div>
    </Login>
    
  </div>
)};

export default MyLoginPage;
