
export interface BillAccountUser {
  id: number
  userId: number
  billAccountId: number
}

export const billingAccountUserRoleChoices = [
  { id: 'owner', name: 'Owner' },
  { id: 'admin', name: 'Administrator' },
  { id: 'user', name: 'User' } 
];


