import React from 'react';
import { Dialog, DialogTitle, Button } from '@mui/material';
import { useNotify, useRefresh, useRecordContext, SimpleForm, ReferenceInput, SelectInput, Toolbar, SaveButton, useCreate, required } from 'react-admin';
import { User } from '../../../Services/users';
import { projectUserroleChoices } from '../../../Services/projectUser';

interface ProjectUserModalCreateProps {
  open: boolean;
  handleClose: () => void;
}

const ProjectUserModalCreate: React.FC<ProjectUserModalCreateProps> = ({ open, handleClose }) => {
  const record = useRecordContext<User>(); // Contexte pour obtenir le user actuel
  const notify = useNotify();
  const refresh = useRefresh();
  const [create] = useCreate();

  const handleSave = (data: any) => {
    // Ajoutez l'ID du User aux données du formulaire
    create(
      'project-users',
      { data: { ...data, projectId: record?.id } },
      {
        onSuccess: () => {
          refresh();
          notify('User added created successfully');
          handleClose();
        },
        onError: (error:any) => {
          notify(`Error: ${error.message}`, { type: 'error' });
        }
      }
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Add User to Project</DialogTitle>
      
        {/* Formulaire pour ajouter un projet utilisateur */}
        <SimpleForm
          onSubmit={handleSave} // Utilisation de handleSave pour soumettre le formulaire
          toolbar={
            <Toolbar style={{justifyContent: "space-between"}}>
              <SaveButton label="Save" />
              <Button onClick={handleClose} color="secondary">Cancel</Button>
            </Toolbar>
          }
        >
          <ReferenceInput label="User" source="userId" reference="users" >
            <SelectInput optionText="name" validate={required()} />
          </ReferenceInput>
          <ReferenceInput label="Role" source="role" reference="projects" >
          <SelectInput source="role" choices={projectUserroleChoices} optionText="name" optionValue="id" validate={required()} />
          </ReferenceInput>
        </SimpleForm>
      
    </Dialog>
  );
};

export default ProjectUserModalCreate;
