import React from 'react';
import { Dialog, DialogTitle, Button } from '@mui/material';
import { SimpleForm, Toolbar, SaveButton, required, TextInput, useNotify, SelectInput } from 'react-admin';
import {addDocument} from '../../../Services/billingAccountContractDocuments';


interface BillingAccountContractDocumentAnnexeModalEditProps {
  open: boolean;
  handleClose: () => void;
  contractId: number;
}

const BillingAccountContractDocumentAnnexeModalEdit: React.FC<BillingAccountContractDocumentAnnexeModalEditProps> = ({ open, handleClose, contractId }) => {
 
  
  const notify = useNotify();

  const handleSave = async (data: any) => {

    try {
      await addDocument(contractId, {
        name: data.name, 
        slug: data.slug, 
        gdriveSrcId: data.gdriveSrcId, 
        num: data.num * 1,
        docType: data.docType,
        auto: false, 
        billingAccountContractID: contractId * 1, 
        signPage: data.signPage * 1,
        signPositionX: data.signPositionX * 1,
        signPositionY: data.signPositionY * 1,

    });
      notify('Document added', { type: 'info' });

      handleClose();
    }catch(e: any) {
      notify(e.message, { type: 'error' });
    }

  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Document to contract</DialogTitle>
      
        {/* Formulaire pour ajouter un projet utilisateur */}
        <SimpleForm
          onSubmit={handleSave} // Utilisation de handleSave pour soumettre le formulaire
          toolbar={
            <Toolbar style={{justifyContent: "space-between"}}>
              <SaveButton label="Save" />
              <Button onClick={handleClose} color="secondary">Cancel</Button>
            </Toolbar>
          }
        >
          <SelectInput source="docType" label="Type" choices={[
            { id: 'annexe', name: 'Annexe' },
            { id: 'contract', name: 'Contract' },
          ]} validate={required()} /> 
          <TextInput source="name" label="Name" validate={required()} />
          <TextInput source="slug" label="Slug" validate={required()} />
          <TextInput source="gdriveSrcId" label="Google Drive ID" validate={required()} /> 
          <TextInput source="num" label="Annexe num" type='number' validate={required()} />
          <TextInput source="signPage" label="Page signature" type='number' validate={required()} />
          <TextInput source="signPositionX" label="Position X" type='number' validate={required()} />
          <TextInput source="signPositionY" label="Position Y" type='number' validate={required()} />

        </SimpleForm>
      
    </Dialog>
  );
};

export default BillingAccountContractDocumentAnnexeModalEdit;
