import { IOfferDetailLine } from "./offerDetailsLine";

export const offerDetailsNbrTypeChoices = [
  { id: 'adaccount', name: 'Ad Account' },
  { id: 'property', name: 'Property' },
  { id: 'connector', name: 'Connector' }, 
  { id: 'instance', name: 'Instance' }, 
];


export interface IOfferDetail {
  id: number
  sku: string
  skuParent: string
  nbr: number
  nbrType: string
  monthlyPrice: number
  annualPrice: number
  linesIncluded: number
  enabled: boolean
  lines: IOfferDetailLine[]
}