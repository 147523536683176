import React from 'react';
import { List, Filter, TextInput, Datagrid, TextField, FunctionField } from 'react-admin';
import ConfirmDeleteButton from '../../Components/Button/ConfirmDeleteBtn';


const ProjectFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('fr-FR'); // Format français : JJ/MM/AAAA
};

export const OfferList = (props: any) => (
  <List {...props} filters={<ProjectFilter />}>
    <Datagrid rowClick={(id, basePath, record) => `${record.id}`}>
      <TextField source="id" />
      <TextField source="name" />
      <FunctionField source="startedAt" render={record => formatDate(record.startedAt)} />
      <FunctionField source="endedAt" render={record => formatDate(record.endedAt)} />
      <TextField source="enabled" />
      <FunctionField render={record => <ConfirmDeleteButton 
        record={record} 
        title='Delete offer' 
        entity='offers'
        description='Are you sure to delete this offer ?'
        confirmMessage='Offer is deleted' />
        } />
    </Datagrid>
  </List>
);