import React, { useState } from 'react';
import { ReferenceManyField, Datagrid, TextField, FunctionField } from 'react-admin';
import { Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import ConfirmDeleteButton from '../../../Components/Button/ConfirmDeleteBtn';
import EditIcon from '@mui/icons-material/Edit';
import OfferDetailsModalCreate from '../OfferDetails/OfferDetailsModalCreate';
import OfferDetailsModalEdit from '../OfferDetails/OfferDetailsModalEdit';
import { BillAccountContract, BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED } from '../../../Services/billingAccountContract';

interface BillingAccountContractProductsProps {
  contract: BillAccountContract;
}

export const BillingAccountContractProducts:React.FC<BillingAccountContractProductsProps> = ({contract}) => {
  
  const [createOpen, setCreateOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleCreateOpen = () => setCreateOpen(true);
  const handleCreateClose = () => setCreateOpen(false);

  const handleEditOpen = (record: any) => {

    setSelectedRecord(record);
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setSelectedRecord(null);
    setEditOpen(false);
  };

  return (

        <Card style={{ marginBottom: 20 }}>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom>
                Products
              </Typography>
              <div>
              {contract.status !== BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED && 
              <Button variant="contained" color="primary" onClick={handleCreateOpen}>
                    +
              </Button>
    }

              </div>
            </div>
            <ReferenceManyField
                label="Offer"
                reference="offer-details"
                target="billingAccountContractId"
                sort={{ field: 'offerDetails.id', order: 'ASC' }}
              >
                <Datagrid bulkActionButtons={false}>
                <TextField source="skuParent" label="Sku Parent" />
                  <TextField source="sku" label="Sku" />
                  <TextField source="nbr" label="nbr" />
                  <TextField source="nbrType" label="nbrType" />
                  <TextField source="annualPrice" label="amount" />
                  <TextField source="linesIncluded" label="linesIncluded" />
                  {contract.status !== BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED && 
                  <FunctionField
                      render={(record) => (
                        <IconButton onClick={() => handleEditOpen(record)} color="primary">
                          <EditIcon />
                        </IconButton>
                      )}
                    />
                  }
                  {contract.status !== BILLING_ACCOUNT_CONTRACT_STATUS_SIGNED && 
                  <FunctionField render={record => <ConfirmDeleteButton 
                    record={record} 
                    title='Delete details on offer' 
                    entity='offer-details'
                    description='Are you sure to delete this line ?'
                    withRedirect={false}
                    confirmMessage='Line is deleted' />
                    } />
                  }
                </Datagrid>
              </ReferenceManyField>
           
          </CardContent>
                  
          <OfferDetailsModalCreate open={createOpen} handleClose={handleCreateClose} />
          <OfferDetailsModalEdit open={editOpen} handleClose={handleEditClose} record={selectedRecord} />
        </Card>

)};

