import React from 'react';
import { BooleanInput, Create, DateInput, SimpleForm, TextInput } from 'react-admin';

export const OfferCreate = (props:any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <DateInput source="startedAt" />
      <DateInput source="endedAt" />
      <BooleanInput label="Enabled" source="enabled" />
    </SimpleForm>
  </Create>
);