import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { useNotify, useRefresh, useRecordContext, useCreate } from 'react-admin';
import { Offer } from '../../../Services/offer';
import OfferDetailForm from './OfferDetailForm';

interface OfferDetailsModalCreateProps {
  open: boolean;
  handleClose: () => void;
  defaultValues?: any;
}

const OfferDetailsModalCreate: React.FC<OfferDetailsModalCreateProps> = ({ open, handleClose, defaultValues }) => {
  const record = useRecordContext<Offer>(); // Contexte pour obtenir le user actuel
  const notify = useNotify();
  const refresh = useRefresh();
  const [create] = useCreate();

  const handleSave = (data: any) => {
    // Ajoutez l'ID du User aux données du formulaire
    create(
      'offer-details',
      { data: { ...data, offerId: record?.id } },
      {
        onSuccess: () => {
          refresh();
          notify('Line created successfully');
          handleClose();
        },
        onError: (error: any) => {
          notify(`Error: ${error.message}`, { type: 'error' });
        }
      }
    );
  };

  console.log(defaultValues);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Detail on offer</DialogTitle>
      <OfferDetailForm onSubmit={handleSave} onCancel={handleClose} initialValues={defaultValues} />
    </Dialog>
  );
};

export default OfferDetailsModalCreate;
